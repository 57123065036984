var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", [
    _c(
      "div",
      { attrs: { id: "app" } },
      [
        _c(
          "mapir",
          {
            attrs: {
              apiKey: _vm.mapirToken,
              center: _vm.coordinates,
              zoom: 13,
            },
            on: { load: _vm.onMapLoaded, click: _vm.mapOnClick },
          },
          [
            _c("mapNavigationControl", { attrs: { position: "top-right" } }),
            _c("mapScaleControl", { attrs: { position: "top-right" } }),
            _c("mapMarker", {
              attrs: {
                coordinates: _vm.markerCoordinates,
                color: "blue",
                draggable: true,
              },
              on: {
                "update:coordinates": function ($event) {
                  _vm.markerCoordinates = $event
                },
                dragend: _vm.getlatLng,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-1 text-center" },
      [
        _c(
          "v-btn",
          {
            staticClass: "col-12",
            attrs: {
              color: "primary",
              large: "",
              loading: _vm.submit_loading,
              disabled: _vm.submit_loading,
            },
            on: {
              click: function ($event) {
                return _vm.submit()
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("Save changes")) + " ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }